import { Component, OnInit, OnDestroy, Inject, ChangeDetectorRef, HostListener } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Subject, Observable } from "rxjs";
import { ToastService } from "app/shared/services/toast.service";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { RootState } from "app/reducers";
import { MCBRepository } from "app/repositories/mcb.repository";
import { takeUntil, debounceTime, startWith, map, take } from "rxjs/operators";
import { UpdateAvatarComponent } from "../update-avatar/update-avatar.component";

@Component({
  selector: "vp-edit-conference-dialog",
  templateUrl: "./edit-conference-dialog.component.html"
})
export class EditConferenceDialogComponent implements OnDestroy, OnInit {
  conferenceName: FormControl;
  description: FormControl;
  searchControl: FormControl;
  password: FormControl;
  userJID: any;
  tags: string[] = [];
  currentTags: string[] = [];
  filteredOptions: Observable<string[]>;
  imageData: any;
  passwordType = "text";
  private isAlive$ = new Subject<boolean>();
  groupInfo: any;
  constructor(private toastService: ToastService,
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<EditConferenceDialogComponent>,
    private store: Store<RootState>,
    private mcbRepo: MCBRepository,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private changeDetectionRef: ChangeDetectorRef) {
    setTimeout(() => {
      if (document.getElementById("conferenceName") !== null) {
        document.getElementById("conferenceName").focus();
      }
    }, 1000);
    this.mcbRepo.getTags().subscribe(tags => {
      this.tags = tags.map(v => v.name);
      console.log("[getTags]", tags);
      this.changeDetectionRef.markForCheck();
    });
    this.mcbRepo.getTagsByJid(this.data.conference.jid).subscribe(objects => {
      if (objects.length > 0 && !!objects[0].tags_list) {
        this.currentTags = objects[0].tags_list.split(",");
        this.changeDetectionRef.markForCheck();
      }
    });
  }

  ngOnInit(): void {
    this.searchControl = new FormControl("", []);
    this.conferenceName = new FormControl(this.data.conference.name, [Validators.required]);
    this.description = new FormControl(this.data.conference.description, []);
    this.password = new FormControl(this.data.conference.password, []);
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );
    // this.searchControl.valueChanges.pipe(debounceTime(500), takeUntil(this.isAlive$),
    // ).subscribe(val => {
    // });
    this.mcbRepo.getGroupInfo(this.data.conference.jid).pipe(take(1)).subscribe(v => {
      console.log("[getGroupInfo]", v);
      if (!!v) {
        this.groupInfo = v;
        this.changeDetectionRef.markForCheck();
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.tags.filter(option => option.toLowerCase().includes(filterValue)
    && !this.currentTags.includes(option.toLowerCase()));
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  @HostListener("document:keydown.esc")
  cancel(): void {
    this.matDialogRef.close();
  }

  selectTag(option) {
    this.searchControl.patchValue("");
    console.log("[selectTag]", option);
    if (!this.currentTags.find(v => v.toLowerCase() === option.toLowerCase())) {
      this.currentTags.push(option);
      this.changeDetectionRef.markForCheck();
    }
  }

  selectTagOnEnter() {
    if (!this.searchControl.value) {
      this.searchControl.patchValue("");
      return;
    }
    if (!this.currentTags.find(v => v.toLowerCase() === this.searchControl.value.toLowerCase())) {
      this.currentTags.push(this.searchControl.value.toLowerCase());
      this.searchControl.patchValue("");
      this.changeDetectionRef.markForCheck();
    }
  }

  removeTag(option) {
    console.log("[removeTag]", option);
    this.currentTags = this.currentTags.filter(v => v.toLowerCase() !== option.toLowerCase());
    this.changeDetectionRef.markForCheck();
  }

  save() {
    const data: any = {};
    if (this.conferenceName.value !== this.data.conference.name) {
      data.subject = this.conferenceName.value;
    }
    if (this.description.value !== this.data.conference.description) {
      data.description = this.description.value;
    }
    if (this.password.value !== this.data.conference.password) {
      data.password = this.password.value;
    }
    if (this.imageData) {
      this.mcbRepo.updateGroupAvatar(this.data.conference.jid, this.imageData.split(",")[1]).subscribe(res2 => {
        console.log("[updateGroupAvatar]", res2);
      });
    }
    this.mcbRepo.createOrUpdateTag(this.data.conference.jid, this.currentTags).subscribe((res: any) => {
      console.log("[createOrUpdateTag]", res);
    });
    if (Object.keys(data).length > 0) {
      this.mcbRepo.updateGroupInfo(this.data.conference.jid, data).subscribe((res: any) => {
        console.log("[updateGroupInfo]", res);
        this.matDialogRef.close(res.group_chat);
      }, err => {
        this.toastService.show(err);
      });
    } else {
      this.matDialogRef.close();
    }
  }

  uploadAvatar() {
    const options: any = {
      width: "340px",
      height: "410px",
    };
    this.matDialog.open(UpdateAvatarComponent, Object.assign({
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel",
      disableClose: true,
      data: {
        action: "update_avatar"
      },
      autoFocus: true
    }, options)).afterClosed().subscribe(res => {
      console.log("[UpdateAvatarComponent]", res);
      if (res && res.photo) {
        this.imageData = res.photo.data;
        this.changeDetectionRef.markForCheck();
      }
    });
  }

  togglePassword() {
    this.passwordType = this.passwordType === "password" ? "text" : "password";
    this.changeDetectionRef.markForCheck();
  }
}
