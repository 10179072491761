import { Injectable, NgZone } from "@angular/core";
import * as _ from "lodash";
import { Store } from "@ngrx/store";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from "rxjs";
import { Subject } from "rxjs/Subject";
import { SetXmppConnection, XmppSession, SetBare } from "../../actions/app";
import * as moment from "moment";
import * as XMPP from "stanza.io";
import { ContactInformation } from "../models/vcard.model";
import { from } from "rxjs";
import * as punycode from "punycode";
import { environment } from "environments/environment";
import { Broadcaster } from "./broadcaster.service";
import { AuthService } from "./auth.service";
import {
  getIsLoggedIn, getUserProfile, getIsLoggedInUserLoaded, getAppSettings, getContactById,
  getUserStatus, getIsConnectedXMPP, getNetworkInformation, getContactStatusById
} from "app/reducers";
import { CommonUtil } from "app/utils/common.util";
import { ConversationConfig } from "app/meta-conference-board/models/conversation.model";
import { JID } from "../models/jid.model";
import { Photo } from "../models/photo.model";
import { MCBRootState } from "app/meta-conference-board/reducers";
import { getUserStatusType } from "../models";
import { take, distinctUntilChanged, filter } from "rxjs/operators";
import { ConfigService } from "app/config.service";

const XMPP_RECONNECTION_INTERVAL = 5000; // each 5 seconds
@Injectable()
export class XmppService {
  public xmpp;
  private LastInactiveTime;
  private xmppLoggedOut = true;
  private isXmppConnected = false;
  private profile: any;
  public conferenceDomain: string;
  private isProfileLoaded = false;
  private nickname: string;
  private isLoggedIn: boolean;
  private networkOnline = true;
  private inBackground = false;
  private _priority = new BehaviorSubject<number>(0);
  private _onMessage = new Subject<any>();
  private _onDeleteMessage = new Subject<string>();
  private _onMessageReceipt = new Subject<string>();
  private _onMucError = new Subject<any>();
  private _onPresence = new Subject<any>();
  private _onMucAvailable = new Subject<any>();
  private _onLogin = new Subject();
  private _onMucInvite = new Subject<any>();
  private sentNickname: boolean;
  private _connectionStatus = new BehaviorSubject<XmppConnectionStatus>(XmppConnectionStatus.Disconnected);
  networkSubscription$: any;
  sendReceipt: boolean;
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;
  private _onStampReceived = new Subject<{ jid: string, timestamp: number }>();
  private reconnectionTimer$: any;

  constructor(private store: Store<MCBRootState>,
    private broadcaster: Broadcaster,
    private configService: ConfigService,
    private authService: AuthService,
    private zone: NgZone) {
  }

  public init() {
    console.log("[XmppService][init]");

    this.store.select(getIsLoggedIn).subscribe(v => this.isLoggedIn = v);

    this.setupXmppConnectionStatusUpdate();
    this.setupXmppIsConnectedStateUpdate();

    const profile$ = this.store.select(getUserProfile);
    //
    profile$.filter(profile => !!profile && !!profile.secret).subscribe(profile => {
      this.profile = profile;
      console.log("[XmppService] getUserProfile", profile);
      if (!this.xmpp) {
        this.setupXMPPConnection();
        this.setupNetworkChangesListener();
        this.login();
      }
      this.store.select(getIsLoggedIn).filter(v => !!v).subscribe(v => {
        console.log("[XmppService] getIsLoggedIn", v, this.xmpp);
        this.isLoggedIn = v;
        this.conferenceDomain = this.configService.conferenceDomain;
      });
    });
    profile$.filter(profile => !!profile && profile.user).pipe(take(1)).subscribe(() => {
      const priority = +localStorage.getItem(this.priorityKey) || 8;
      this.setPriority(priority);
    });

    this.store.select(getIsLoggedInUserLoaded).subscribe(loaded => this.isProfileLoaded = loaded);
    this.store.select(getAppSettings)
      .pipe(distinctUntilChanged())
      .subscribe(options => {
        this.sendReceipt = options.receipts;
      });
  }

  private get priorityKey(): string {
    const id = this.profile && this.profile.user ? this.profile.user : 0;
    return id + ":priority";
  }

  getOnMessage(): Observable<any> {
    return this._onMessage.asObservable();
  }

  getOnDeleteMessage(): Observable<string> {
    return this._onDeleteMessage.asObservable();
  }

  getOnMessageReceipt(): Observable<string> {
    return this._onMessageReceipt.asObservable();
  }

  getOnStampReceived(): Observable<{ jid: string, timestamp: number }> {
    return this._onStampReceived.asObservable();
  }

  getOnMucError(): Observable<any> {
    return this._onMucError.asObservable();
  }

  getOnPresence(): Observable<any> {
    return this._onPresence.asObservable();
  }

  getOnMucAvailable(): Observable<any> {
    return this._onMucAvailable.asObservable();
  }

  getOnMucInvite(): Observable<any> {
    return this._onMucInvite.asObservable();
  }

  getPriority(): Observable<number> {
    return this._priority.asObservable().pipe(distinctUntilChanged());
  }

  setPriority(priority: number) {
    localStorage.setItem(this.priorityKey, priority.toString());
    this._priority.next(priority);
  }

  /**
   * Operations on room/Conversation
   */
  block(conversationTarget: string): Observable<boolean> {
    const response = new Subject<boolean>();
    this.xmpp.block(conversationTarget, (err, data) => {
      this.zone.run(() => {
        if (err) {
          response.next(false);
        } else {
          response.next(true);
        }
      });
    });

    return response.asObservable().pipe(take(1));
  }

  unblock(conversationTarget: string): Observable<boolean> {
    const response = new Subject<boolean>();
    this.xmpp.unblock(conversationTarget, (err, data) => {

      this.zone.run(() => {
        if (err) {
          response.next(false);
        } else {
          response.next(true);
        }

        this.sendPresence();
        this.xmpp.sendPresence({
          to: conversationTarget,
          type: "probe"
        });
      });
    });

    return response.asObservable().pipe(take(1));
  }

  joinRoom(roomId: string) {
    console.log("[XmppService][joinRoom]", roomId);

    if (this.xmpp) {
      this.xmpp.joinRoom(roomId, this.xmpp.jid.bare, {
        history: {
          maxstanzas: 0
        }
      });
    }
  }

  sendPresence(nickname?: string) {
    console.log("[XmppService][sendPresence]", nickname);
    let status;

    this.store.select(getUserStatus).pipe(take(1)).subscribe(res => status = res);
    status = getUserStatusType(status);
    let options: any = {
      show: status.slug,
      priority: this._priority.getValue(),
      caps: this.xmpp.disco.caps
    };
    if (status.slug === "available") {
      options = {
        priority: this._priority.getValue(),
        caps: this.xmpp.disco.caps
      };
    }
    if (nickname) {
      this.nickname = nickname;
    }
    if (this.nickname) { // use old nickname
      options.nick = this.nickname;
    }
    this.xmpp.sendPresence(options);
  }

  private setupXmppConnectionStatusUpdate() {
    // here we track changes Disconnected -> SessionStarted and SessionStarted -> Disconnected
    this._connectionStatus.filter(status => status === XmppConnectionStatus.Disconnected
      || status === XmppConnectionStatus.SessionStarted)
      .map(status => status === XmppConnectionStatus.SessionStarted)
      .subscribe(status => {
        this.store.dispatch(new SetXmppConnection(status));

        // if we are in a 'Disconnected' state, then tried to connect again and
        // failed by timeout (e.g we have a wifi, but no Internet connection) then a 'Disconnected' state will be called again
        // BUT, the below 'this.store.select(getIsConnectedXMPP)' will not be called again because a select is firing only on changes
        // so we need to fire a reconnect manually if we faced a onnect timeout issue
        //
        // See below what we do in a "stream:error" xmpp callback when "connection-timeout" happened.
      });
  }

  private setupXmppIsConnectedStateUpdate() {
    // this.appStore.map(state => state.isXmppConnected).subscribe(chatConnected => { });

    this.store.select(getIsConnectedXMPP).subscribe(chatConnected => {
      console.log(moment().format("LTS") + " [NETWORK] [XmppService][getIsConnectedXMPP]", chatConnected);
      console.log("[XmppService][getIsConnectedXMPP]", chatConnected);
      this.isXmppConnected = chatConnected;


      if (this.isXmppConnected) {
        this.invalidateReconnectionTimer();
        this.enableKeepAlive();
        if (environment.isCordova) {
          this.markActive();
        }
      } else {
        this.runReconnectionTimer();
      }
    });
  }

  private runReconnectionTimer() {
    if (this.reconnectionTimer$) {
      console.log(moment().format("LTS") + " [NETWORK][XmppService][runReconnectionTimer] return, timer already defined");
      console.log("[XmppService][runReconnectionTimer] return, timer already defined");
      return;
    }

    console.log("[XmppService][runReconnectionTimer]");
    this.reconnectionTimer$ = Observable.timer(0, XMPP_RECONNECTION_INTERVAL).subscribe(() => {
      this.tryToReconnect();
    });
  }

  private invalidateReconnectionTimer() {
    console.log(moment().format("LTS") + " [NETWORK][XmppService][invalidateReconnectionTimer]");
    console.log("[XmppService][invalidateReconnectionTimer]");
    if (this.reconnectionTimer$) {
      this.reconnectionTimer$.unsubscribe();
      this.reconnectionTimer$ = null;
    }
  }

  private setupNetworkChangesListener() {
    if (this.networkSubscription$) {
      return;
    }
    this.networkSubscription$ = this.store.select(getNetworkInformation).pipe(filter(v => !!v),
    distinctUntilChanged()).subscribe(information => {
      console.log("[XmppService][getNetworkInformation]", information.onlineState, information, typeof information.inBackground);
      this.networkOnline = information.onlineState;
      this.inBackground = information.inBackground; // 'inBackground' only for mobile (based on pause/resume events)
      if (typeof this.inBackground !== "undefined") {
        if (this.inBackground) {
          console.log("[XmppService] going to mark inactive and disable keepalive");
          this.markInactive();
          this.disableKeepAlive();
        } else {
          this.markActive();
          this.enableKeepAlive();
        }
      }
      this.tryToReconnect();
    });
  }

  private tryToReconnect() {
    if (!this.isXmppConnected && this.networkOnline
      && this.isLoggedIn && !this.xmppLoggedOut && !this.inBackground) {
      console.log(moment().format("LTS") + " [NETWORK][XmppService][tryToReconnect]: Trying to restore XMPP connection");
      console.log("[XmppService][tryToReconnect]: Trying to restore XMPP connection");
      this.login();
    } else {
      this.invalidateReconnectionTimer();
    }
  }

  private setupXMPPConnection() {
    console.log(moment().format("LTS") + " [NETWORK][XmppService][setupXMPPConnection]");
    console.log("[XmppService][setupXMPPConnection]");

    this.xmppLoggedOut = false;

    const basicConfig = {
      sendReceipts: true,
      lang: "en",
      timeout: environment.xmppTimeout
    };

    basicConfig["transport"] = "websocket";
    basicConfig["wsURL"] = this.profile.xmppWsUrl;
    basicConfig["useStreamManagement"] = true;
    this.xmpp = XMPP.createClient(basicConfig);
    this.configureXMPPClient();

    this.registerForEvents();
  }

  private login() {
    const moreThan5MinsInBackground = (Date.now() / 1000 - this.LastInactiveTime) > 300;
    console.log("[XmppService][login], moreThan5MinsInBackground: ", moreThan5MinsInBackground);

    if (this._connectionStatus.getValue() === XmppConnectionStatus.Connecting) {
      console.log(moment().format("LTS") + " [NETWORK][XMPPService][login], state is CONNECTING so skip a login.");
      console.log("[XMPPService][login], state is CONNECTING so skip a login.");
      return;
    }
    if (this._connectionStatus.getValue() === XmppConnectionStatus.Connected) {
      console.log(moment().format("LTS") + " [NETWORK][XMPPService][XMPPService][login], state is CONNECTED so skip a login.");
      console.log("[XMPPService][login], state is CONNECTED so skip a login.");
      return;
    }
    if (this._connectionStatus.getValue() === XmppConnectionStatus.SessionStarted) {
      console.log(moment().format("LTS") + " [NETWORK][XMPPService][login], state is SESSION-STARTED so skip a login.");
      console.log("[XMPPService][login], state is SESSION-STARTED so skip a login.");
      return;
    }

    if (moreThan5MinsInBackground) {
      // manually prevent a session resumption
      console.log(moment().format("LTS") + " [NETWORK][XMPPService][login] reset SM");
      console.log("[XMPPService][login] reset SM");
      this.xmpp.sm.failed();
    }

    let xmppResourceName = localStorage.getItem("xmppResourceName");
    if (environment.isCordova) {
      if (!xmppResourceName || (xmppResourceName && xmppResourceName.indexOf("vnctalk") !== -1)) {
        // xmppResourceName = new Date().getTime().toString() + "-" + device.uuid;
        xmppResourceName = device.uuid;
        localStorage.setItem("xmppResourceName", xmppResourceName);
      }
    } else if (!environment.isCordova && !xmppResourceName) {
      xmppResourceName = "vnctalk_" + CommonUtil.randomId(10);
      localStorage.setItem("xmppResourceName", xmppResourceName);
    }
    const jid = Array.isArray(this.profile.user.email) ? this.profile.user.email[0] : this.profile.user.email;
    this.store.dispatch(new SetBare(jid));

    const connectUserConfig = {
      jid: jid,
      password: this.profile.secret
    };

    if (!xmppResourceName) {
      xmppResourceName = "vnctalk_" + CommonUtil.randomId(10);
      localStorage.setItem("xmppResourceName", xmppResourceName);
    }

    this.xmpp.config["resource"] = xmppResourceName;

    console.log("[XmppService][login] connectUserConfig:", connectUserConfig);
    console.log("[XmppService][login] this.xmpp:", this.xmpp);

    this._connectionStatus.next(XmppConnectionStatus.Connecting);
    this.xmpp.connect(connectUserConfig);
  }

  private configureXMPPClient() {
    console.log("[XMPPService][configureXMPPClient]");

    this._setUpHTTPUploadModule();
    this._setUpMUCRegisterModule();
    this._setupLastActivityModule();
    this._setupLastActivityBatchModule();
    this._setUpCustomStanzaHadler();

    this.xmpp.uploadGroupAvatar = (conversationTarget, photo, cb) => {
      return this.xmpp.sendIq({
        to: conversationTarget,
        type: "set",
        vCardTemp: { photo: photo }
      }, cb);
    };

    this.xmpp.disco.addFeature("urn:xmpp:message-correct:0");

    const NS = "xmpp:vnctalk";
    const utils = this.xmpp.stanzas.utils;
    const messageAttribute = this.xmpp.stanzas.define({
      name: "attachment",
      element: "attachment",
      namespace: NS,
      fields: {
        url: utils.textSub(NS, "url"),
        fileSize: utils.textSub(NS, "fileSize"),
        fileName: utils.textSub(NS, "fileName"),
        fileType: utils.textSub(NS, "fileType")
      }
    });


    const JsonDocument = this.xmpp.stanzas.define({
      name: "_document",
      element: "document",
      namespace: "stanza:io:json",
      fields: {
        key: utils.attribute("key"),
        value: utils.text()
      }
    });
    const JsonDocuments = this.xmpp.stanzas.define({
      name: "documents",
      namespace: "stanza:io:json",
      element: "documents"
    });

    this.xmpp.stanzas.extend(JsonDocuments, JsonDocument, "list");
    this.xmpp.stanzas.withDefinition("query", "jabber:iq:private", (PrivateStorage) => {
      this.xmpp.stanzas.extend(PrivateStorage, JsonDocuments);
    });



    /**
     * Docs is a Map of key: value.
     * This function will do all the necessary XML/JSON encoding.
     */
    this.xmpp.setPrivateDocuments = (docs, cb) => {
      const encodedDocs = [];
      _.forOwn(docs, (value, key) => {
        encodedDocs.push({
          key: key,
          value: JSON.stringify(value)
        });
      });

      this.xmpp.setPrivateData({ documents: { list: encodedDocs } }, cb);
    };

    const locationAttribute = this.xmpp.stanzas.define({
      name: "location",
      element: "location",
      namespace: NS,
      fields: {
        lat: utils.textSub(NS, "lat"),
        lng: utils.textSub(NS, "lng"),
        address: utils.textSub(NS, "address"),
        label: utils.textSub(NS, "label")
      }
    });

    const notificationAttribute = this.xmpp.stanzas.define({
      name: "notification",
      element: "notification",
      namespace: NS,
      fields: {
        type: utils.textSub(NS, "type"),
        action: utils.textSub(NS, "action"),
        target: utils.textSub(NS, "target"),
        content: utils.textSub(NS, "content")
      }
    });

    const vncTalkConference = this.xmpp.stanzas.define({
      name: "vncTalkConference",
      element: "vncTalkConference",
      namespace: NS,
      fields: {
        from: utils.textSub(NS, "from"),
        to: utils.textSub(NS, "to"),
        conferenceId: utils.textSub(NS, "conferenceId"),
        oldConferenceId: utils.textSub(NS, "oldConferenceId"),
        jitsiRoom: utils.textSub(NS, "jitsiRoom"),
        jitsiURL: utils.textSub(NS, "jitsiURL"),
        jitsiXmppUrl: utils.textSub(NS, "jitsiXmppUrl"),
        jitsiXmppPort: utils.textSub(NS, "jitsiXmppPort"),
        reason: utils.textSub(NS, "reason"),
        conferenceType: utils.textSub(NS, "conferenceType"),
        duration: utils.textSub(NS, "duration"),
        eventType: utils.textSub(NS, "eventType"),
        timestamp: utils.textSub(NS, "timestamp"),
        skipAddUserToChatWhenAddToCall: utils.textSub(NS, "skipAddUserToChatWhenAddToCall")
      }
    });

    const meetingMessage = this.xmpp.stanzas.define({
      name: "meetingMessage",
      element: "meetingMessage",
      namespace: NS,
      fields: {
        to: utils.textSub(NS, "to")
      }
    });

    const toList = {
      set: function set(lists) {
        const self = this;
        lists.forEach(function(bare) {
          const to = utils.createElement(NS, "to", NS);
          utils.setText(to, bare.toString());
          self.xml.appendChild(to);
        });
      }
    };

    const rosterList = {
      set: function set(lists) {
        const self = this;
        lists.forEach(function(name) {
          const roster = utils.createElement(NS, "roster", NS);
          utils.setText(roster, name.toString());
          self.xml.appendChild(roster);
        });
      }
    };

    const vncTalkBroadcast = this.xmpp.stanzas.define({
      name: "vncTalkBroadcast",
      element: "vncTalkBroadcast",
      namespace: NS,
      fields: {
        title: utils.attribute("title"),
        origtarget: utils.attribute("origtarget"),
        to: toList,
        roster: rosterList,
        avatarup: utils.attribute("avatarup")
      }
    });


    const vncTalkMuc = this.xmpp.stanzas.define({
      name: "vncTalkMuc",
      element: "vncTalkMuc",
      namespace: NS,
      fields: {
        from: utils.textSub(NS, "from"),
        to: utils.textSub(NS, "to"),
        conferenceId: utils.textSub(NS, "conferenceId"),
        eventType: utils.textSub(NS, "eventType")
      }
    });

    const groupActionMessage = this.xmpp.stanzas.define({
      name: "group_action",
      element: "group_action",
      namespace: NS,
      fields: {
        type: utils.textSub(NS, "type")
      }
    });

    const replaceMessage = this.xmpp.stanzas.define({
      name: "replace",
      element: "replace",
      namespace: "urn:xmpp:message-correct:0",
      fields: {
        id: utils.attribute("id"),
        value: utils.text()
      }
    });
    const originalMessage = this.xmpp.stanzas.define({
      name: "originalMessage",
      element: "originalMessage",
      namespace: NS,
      fields: {
        id: utils.textSub(NS, "id"),
        from: utils.textSub(NS, "from"),
        body: utils.textSub(NS, "body"),
        timestamp: utils.textSub(NS, "timestamp"),
        htmlBody: utils.textSub(NS, "htmlBody"),
        attachment: utils.textSub(NS, "attachment"),
        location: utils.textSub(NS, "location"),
        replyMessage: utils.textSub(NS, "replyMessage"),
        broadcast_id: utils.textSub(NS, "broadcast_id"),
        broadcast_owner: utils.textSub(NS, "broadcast_owner"),
        broadcast_title: utils.textSub(NS, "broadcast_title")
      }
    });

    const forwardMessage = this.xmpp.stanzas.define({
      name: "forwardMessage",
      element: "forwardMessage",
      namespace: NS,
      fields: {
        id: utils.textSub(NS, "id"),
        from: utils.textSub(NS, "from"),
        timestamp: utils.textSub(NS, "timestamp")
      }
    });

    const htmlMessage = this.xmpp.stanzas.define({
      name: "html",
      element: "html",
      namespace: "http://jabber.org/protocol/xhtml-im",
      fields: {
        body: utils.textSub("http://www.w3.org/1999/xhtml", "body")
      }
    });

    const startFile = this.xmpp.stanzas.define({
      name: "startFile",
      element: "startFile",
      namespace: NS,
      fields: {
        type: utils.textSub(NS, "type")
      }
    });

    const stamp = this.xmpp.stanzas.define({
      name: "stamp",
      element: "stamp",
      namespace: "xmpp:vnctalk:stamp",
      fields: {
        stamp: utils.attribute("stamp"),
        from: utils.attribute("from")
      }
    });


    this.xmpp.stanzas.withMessage((Message) => {
      this.xmpp.stanzas.extend(Message, notificationAttribute);
      this.xmpp.stanzas.extend(Message, messageAttribute);
      this.xmpp.stanzas.extend(Message, locationAttribute);
      this.xmpp.stanzas.extend(Message, replaceMessage);
      this.xmpp.stanzas.extend(Message, groupActionMessage);
      this.xmpp.stanzas.extend(Message, vncTalkConference);
      this.xmpp.stanzas.extend(Message, vncTalkBroadcast);
      this.xmpp.stanzas.extend(Message, vncTalkMuc);
      this.xmpp.stanzas.extend(Message, originalMessage);
      this.xmpp.stanzas.extend(Message, forwardMessage);
      this.xmpp.stanzas.extend(Message, startFile);
      this.xmpp.stanzas.extend(Message, stamp);
      this.xmpp.stanzas.extend(Message, htmlMessage);
      this.xmpp.stanzas.extend(Message, meetingMessage);
    });

    this.xmpp.stanzas.define({
      name: "iq",
      namespace: "jabber:client",
      element: "iq",
      topLevel: true,
      fields: {
        t: utils.numberAttribute("t"), // message timestamp
        f: utils.attribute("f"), // message id
        id: utils.attribute("id"),
        to: utils.jidAttribute("to", true),
        from: utils.jidAttribute("from", true),
        type: utils.attribute("type")
      }
    });


    this.extendVCardTemp();
  }

  private extendVCardTemp(): void {
    const JXT = this.xmpp.stanzas;
    const Utils = JXT.utils;
    const NS = "vcard-temp";
    const VCardTemp = JXT.define({
      name: "vCardTemp",
      namespace: NS,
      element: "vCard",
      fields: {
        role: Utils.textSub(NS, "ROLE"),
        website: Utils.textSub(NS, "URL"),
        vncAppUser: Utils.textSub(NS, "X-VNC-APPUSER"),
        title: Utils.textSub(NS, "TITLE"),
        description: Utils.textSub(NS, "DESC"),
        fullName: Utils.textSub(NS, "FN"),
        birthday: Utils.dateSub(NS, "BDAY"),
        nicknames: Utils.multiTextSub(NS, "NICKNAME"),
        jids: Utils.multiTextSub(NS, "JABBERID")
      }
    });

    const Email = JXT.define({
      name: "_email",
      namespace: NS,
      element: "EMAIL",
      fields: {
        email: Utils.textSub(NS, "USERID"),
        home: Utils.boolSub(NS, "HOME"),
        work: Utils.boolSub(NS, "WORK"),
        preferred: Utils.boolSub(NS, "PREF")
      }
    });

    const PhoneNumber = JXT.define({
      name: "_tel",
      namespace: NS,
      element: "TEL",
      fields: {
        number: Utils.textSub(NS, "NUMBER"),
        home: Utils.boolSub(NS, "HOME"),
        work: Utils.boolSub(NS, "WORK"),
        mobile: Utils.boolSub(NS, "CELL"),
        preferred: Utils.boolSub(NS, "PREF")
      }
    });

    const Address = JXT.define({
      name: "_address",
      namespace: NS,
      element: "ADR",
      fields: {
        street: Utils.textSub(NS, "STREET"),
        street2: Utils.textSub(NS, "EXTADD"),
        country: Utils.textSub(NS, "CTRY"),
        city: Utils.textSub(NS, "LOCALITY"),
        region: Utils.textSub(NS, "REGION"),
        postalCode: Utils.textSub(NS, "PCODE"),
        pobox: Utils.textSub(NS, "POBOX"),
        home: Utils.boolSub(NS, "HOME"),
        work: Utils.boolSub(NS, "WORK"),
        preferred: Utils.boolSub(NS, "PREF")
      }
    });

    const Organization = JXT.define({
      name: "organization",
      namespace: NS,
      element: "ORG",
      fields: {
        name: Utils.textSub(NS, "ORGNAME"),
        unit: Utils.textSub(NS, "ORGUNIT")
      }
    });

    const Name = JXT.define({
      name: "name",
      namespace: NS,
      element: "N",
      fields: {
        family: Utils.textSub(NS, "FAMILY"),
        given: Utils.textSub(NS, "GIVEN"),
        middle: Utils.textSub(NS, "MIDDLE"),
        prefix: Utils.textSub(NS, "PREFIX"),
        suffix: Utils.textSub(NS, "SUFFIX")
      }
    });

    const Photo = JXT.define({
      name: "photo",
      namespace: NS,
      element: "PHOTO",
      fields: {
        type: Utils.textSub(NS, "TYPE"),
        data: Utils.textSub(NS, "BINVAL"),
        url: Utils.textSub(NS, "EXTVAL")
      }
    });

    JXT.extend(VCardTemp, Email, "emails");
    JXT.extend(VCardTemp, Address, "addresses");
    JXT.extend(VCardTemp, PhoneNumber, "phoneNumbers");
    JXT.extend(VCardTemp, Organization);
    JXT.extend(VCardTemp, Name);
    JXT.extend(VCardTemp, Photo);
    JXT.extendIQ(VCardTemp);
  }

  private registerForEvents() {
    console.log("[XmppService.registerForEvents]");

    this.xmpp.on("session:started", this.onSessionStarted.bind(this));

    this.xmpp.on("stream:management:resumed", this.onSessionResumed.bind(this));

    this.xmpp.on("auth:failed", (data) => {
      console.error("[XmppService] auth:failed", data);
      this._connectionStatus.next(XmppConnectionStatus.Disconnected);
    });

    this.xmpp.on("bosh:terminate", (data) => {
      console.error("[XmppService][new.xmpp.onBoshTerminate]", data);
      this._connectionStatus.next(XmppConnectionStatus.Disconnected);
    });

    this.xmpp.on("stream:error", (data) => {
      console.error("[XmppService][stream:error]", data);

      // timeout on keep alive
      if (data.condition === "connection-timeout") {
        console.error("[XmppService][stream:error]", "connection-timeout");

        this.runReconnectionTimer();
      }
    });

    this.xmpp.on("stream:management:enabled", (data) => {
      console.log("[XmppService][stream:management:enabled]", data);
    });

    this.xmpp.on("stream:management:failed", (data) => {
      console.error("[new.xmpp.onStreamManagementFailed]", "WARNING!: we should not be there!", data);

      // WARNING: we should not be there!
      // If we spent more than 5 mins in background then we start a login process from scratch.

      // we spent to much time in background, so stream resumption is not possible.
      // so we disconnect and try to login again, from scratch
      this.xmpp.disconnect();
    });

    this.xmpp.on("disconnected", (data) => {
      console.error("[XmppService][disconnected]");
      this._connectionStatus.next(XmppConnectionStatus.Disconnected);
    });

    this.xmpp.on("connected", (data, x) => {
      // DO NOT SET XMPP AS CONNECTED HERE BECAUSE IT'S NOT.
      // AUTHENTICATION IS DONE AFTER THIS
      this._connectionStatus.next(XmppConnectionStatus.Connected);
    });

    // this.xmpp.on("carbon:received", (data) => {
    //   // PRASHANT_COMMENT not being called??
    //   if (data.carbonReceived && data.carbonReceived.forwarded && data.carbonReceived.forwarded.message
    //     && data.carbonReceived.forwarded.message.chatState) {
    //     // TODO: use redux here to keep track of currently typing users, instead of broadcaster.
    //     this.zone.run(() => {
    //       this.broadcaster.broadcast("onChatState", data.carbonReceived.forwarded.message);
    //     });
    //   }
    // });

    // this.xmpp.on("roster:ver", (data) => {
    //   console.log("[xmpp.on.rosterVer]", data);
    // });


    // this.xmpp.on("message", (message) => {
    //   console.log("[XMPPService][on message]: ", message);
    //   if (message.mamItem || message.mamResult || (message.muc && !message.muc.jid) || message.subject || message.carbonReceived) {
    //     return;
    //   }

    //   if (message.muc && message.delay) {
    //     return;
    //   }

    //   if (message.error) {
    //     return;
    //   }

    //   let msg = message;
    //   let isForwarded = false;
    //   let timestamp = new Date().getTime();

    //   if (message.stamp && message.stamp.stamp) {
    //     timestamp = (+message.stamp.stamp) * 1000;

    //     // upgrade 'last seen'
    //     const sentFrom = message.from.bare;
    //     this.store.select(state => getContactStatusById(state, sentFrom)).pipe(take(1)).subscribe(v => {
    //       const status = getUserStatusType(v).slug;
    //       if (status !== "online") {
    //         this._onStampReceived.next({ jid: sentFrom, timestamp: timestamp });
    //       }
    //     });
    //   } else if (message.carbonSent) {
    //     const forwarded = message.carbonSent.forwarded;
    //     msg = forwarded.message;
    //     isForwarded = true;
    //     if (msg.stamp && msg.stamp.stamp) {
    //       timestamp = (+msg.stamp.stamp) * 1000;
    //     } else if (msg.forwarded && msg.forwarded.delay) {
    //       timestamp = new Date(msg.forwarded.delay.stamp).getTime();
    //     } else if (msg.delay && msg.delay.stamp) {
    //       timestamp = new Date(msg.delay.stamp).getTime();
    //     }
    //   } else if (!!message.delay) {
    //     timestamp = new Date(msg.delay.stamp).getTime();
    //   }
    // });

    this.xmpp.on("muc:declined", (data) => {
      console.error("muc:declined", data);
    });

    this.xmpp.on("muc:error", (data) => {
      console.error("muc:error", data);
      // https://redmine.vnc.biz/issues/30003052-1849 failed to join group chat: HANDLE ME!
      this._onMucError.next(data);
    });

    this.xmpp.on("muc:unavailable", (data) => {
      console.error("muc:unavailable", data);
    });

    this.xmpp.on("muc:destroyed", (data) => {
      console.error("muc:destroyed", data);
    });

    // fires when receive presence type=available'
    this.xmpp.on("muc:available", (data) => {
      console.log("muc:available", data);
      if (data.muc) {
        this.zone.run(() => {
          this._onMucAvailable.next({
            ...data,
            from: { ...data.from },
            to: { ...data.to },
            muc: {
              ...data.muc,
              jid: { ...data.muc.jid }
            }
          });
        });
      }
    });

    // this.xmpp.on("chat:state", (msg) => {
    //   // TODO: use redux here to keep track of currently typing users, instead of broadcaster.
    //   // TODO: remove zone.run from anywhere we are subscribing to onChatState broadcast
    //   this.zone.run(() => {
    //     console.log("chat:state", msg);
    //     this.broadcaster.broadcast("onChatState", msg);
    //   });
    // });

    // this.xmpp.on("muc:invite", (data) => {

    //   console.log("[XmppService.onMucInvite]", data);
    //   this._onMucInvite.next(data);
    // });

    // this.xmpp.on("muc:join", (data) => {
    //   console.log("[XmppService][on muc:join]", data);
    // });

    // this.xmpp.on("receipt", (data) => {
    //   console.log("[XMPPService] on receipt", data);
    //   // this._onMessageReceipt.next(data.id);
    //   if (data.receipt !== null) {
    //     this._onMessageReceipt.next(data.receipt);
    //   } else {
    //     console.log("[XMPPService] on receipt - empty received child?");
    //   }
    // });

    // this.xmpp.on("nick", (err, data) => {
    //   console.log("[nick]", err, data);
    // });

    // this.xmpp.on("muc:subject", (data) => {

    //   const target = data.from.bare;
    //   const subject = data.subject;
    // });

    // this.xmpp.on("presence", (data, error) => {
    //   if (data && !error) {
    //     console.log("[xmpp.onPresence]", data.from.bare, data, error);
    //     // spreading from and to to convert into simple JS objects from JID objects
    //     this._onPresence.next({ ...data, from: { ...data.from }, to: { ...data.to } });
    //   }
    // });
  }

  private onSessionStarted() {
    this.store.dispatch(new XmppSession({ ...this.xmpp.jid }));
    this._connectionStatus.next(XmppConnectionStatus.SessionStarted);

    this.xmpp.updateCaps();
    this.xmpp.enableCarbons();

    // set conf domain
    this.conferenceDomain = `conference.${this.xmpp.jid.domain}`;
    localStorage.setItem("conferenceDomain", this.conferenceDomain);
    console.log("[XmppService][onSessionStarted] conferenceDomain", this.conferenceDomain, this.xmpp);

    this.sendPresence();
  }

  private onSessionResumed() {
    console.log("[XmppService][onSessionResumed]", this.xmpp.jid, this.xmpp);

    this.store.dispatch(new XmppSession({ ...this.xmpp.jid }));

    this._connectionStatus.next(XmppConnectionStatus.SessionStarted);

    this.xmpp.updateCaps();
    this.xmpp.enableCarbons();

    this.sendPresence();
  }

  public sendMessage(target: string, message: any) {
    if (!this.xmpp) {
      return;
    }

    const msg = { ...message, to: target, from: this.xmpp.jid };
    if (msg.type && msg.type === "groupchat") {
      msg["nick"] = this.xmpp.jid.local;
    }
    if (msg.vncTalkConference) {
      msg.vncTalkConference.from = this.xmpp.jid.bare;
    }
    // ToDo: this should not be required
    // later for groupchat?
    if (msg.type === "chat" && msg.body) {
      msg.requestReceipt = this.sendReceipt;
    }
    console.log("[XmppService][sendMessage] to " + target, msg);

    this.xmpp.sendMessage(msg);

    return msg;
  }

  public createRoom(name?: string, isTemporary?: boolean): Observable<string> {
    console.log("[XmppService][createRoom]", name, this.conferenceDomain);

    const response = new Subject<string>();

    if (!this.conferenceDomain) {
      // TODO: need to create a consistent error model for whole app.
      response.error({ error: "conferenceDomain not set" });
    }

    if (name) {
      this.getUniqueNameFromSubject(name, bare => {
        this.zone.run(() => {
          console.log("[XmppService][createRoom] getUniqueNameFromSubject", bare);
          if (!!bare) {
            response.next(bare);
          } else {
            response.error({ error: "Room with this name already exists" });
          }
        });
      }, isTemporary);
    } else {
      this.getUniqueName(bare => {
        this.zone.run(() => {
          console.log("[XmppService][createRoom] getUniqueName", bare);
          response.next(bare);
        });
      }, isTemporary);
    }

    return response.asObservable().pipe(take(1));
  }

  public createMeetingRoom(name?: string): Observable<string> {
    console.log("[XmppService][createMeetingRoom]", name, this.conferenceDomain);

    const response = new Subject<string>();

    if (!this.conferenceDomain) {
      // TODO: need to create a consistent error model for whole app.
      response.error({ error: "conferenceDomain not set" });
    }

    if (name) {
      this.getUniqueMeetingNameFromSubject(name, bare => {
        this.zone.run(() => {
          console.log("[XmppService][createRoom] getUniqueMeetingNameFromSubject", bare);
          if (!!bare) {
            response.next(bare);
          } else {
            response.error({ error: "Room with this name already exists" });
          }
        });
      });
    } else {
      this.getUniqueMeetingName(bare => {
        this.zone.run(() => {
          console.log("[XmppService][createRoom] getUniqueMeetingName", bare);
          response.next(bare);
        });
      });
    }

    return response.asObservable().pipe(take(1));
  }

  private getUniqueNameFromSubject(name: string, cb: (bare) => void, isTemporary?: boolean) {
    try {
      let bare = punycode.toASCII(name.toLowerCase().trim().replace(/\s+/g, "__")) + "_talk@" + this.conferenceDomain;
      if (isTemporary) {
        bare = punycode.toASCII(name.toLowerCase().trim().replace(/\s+/g, "__")) + "_temporary_group@" + this.conferenceDomain;
      }
      this.xmpp.getUniqueRoomName(bare, (err, res) => {
        if (!res && err.error && err.error.condition === "item-not-found" && err.from) {
          // name we generated doesn't exists
          cb(err.from.bare);
        } else if (err.error && err.error.condition === "jid-malformed") {
          this.getUniqueName(cb, isTemporary);
        } else {
          // name we generated already exists
          const randomId = CommonUtil.randomId(5);
          const newName = `${name}_${randomId}`;
          this.getUniqueNameFromSubject(newName, cb);
        }
      });
    } catch (ex) {
      this.getUniqueName(cb, isTemporary);
      console.error("[XmppService][getUniqueNameFromSubject] err", ex);
    }
  }

  private getUniqueMeetingNameFromSubject(name: string, cb: (bare) => void) {
    try {
      name = name.replace(/\//g, "");
      const randomId = CommonUtil.randomId(12);
      const newName = `${name}_${randomId}`;
      const bare = punycode.toASCII(newName.toLowerCase().trim().replace(/\s+/g, "__")) + "_talk_meeting@" + this.conferenceDomain;
           if (!this.xmpp) {
        alert("XMPP is not connected!");
        return;
      }
      this.xmpp.getUniqueRoomName(bare, (err, res) => {
        if (!res && err.error && err.error.condition === "item-not-found" && err.from) {
          // name we generated doesn't exists
          cb(err.from.bare);
        } else if (err.error && err.error.condition === "jid-malformed") {
          this.getUniqueMeetingName(cb);
        } else {
          // name we generated already exists
          const randomId = CommonUtil.randomId(5);
          const newName = `${name}_${randomId}`;
          this.getUniqueMeetingNameFromSubject(newName, cb);
        }
      });
    } catch (ex) {
      this.getUniqueMeetingName(cb);
      console.error("[XmppService][getUniqueNameFromSubject] err", ex);
    }
  }

  private getUniqueName(cb: (bare) => void, isTemporary?: boolean) {
    let name = CommonUtil.randomId(12) + "_talk" + "@" + this.conferenceDomain;
    if (isTemporary) {
      name = CommonUtil.randomId(12) + "_temporary_group" + "@" + this.conferenceDomain;
    }
    this.xmpp.getUniqueRoomName(name, (err, res) => {
      if (!res && err.error && err.error.condition === "item-not-found" && err.from) {
        // name we generated doesn't exists
        cb(err.from.bare);
      } else {
        // name we generated already exists
        this.getUniqueName(cb, isTemporary);
      }
    });
  }

  private getUniqueMeetingName(cb: (bare) => void) {
    const name = CommonUtil.randomId(12) + "_talk_meeting" + "@" + this.conferenceDomain;

    this.xmpp.getUniqueRoomName(name, (err, res) => {
      if (!res && err.error && err.error.condition === "item-not-found" && err.from) {
        // name we generated doesn't exists
        cb(err.from.bare);
      } else {
        // name we generated already exists
        this.getUniqueMeetingName(cb);
      }
    });
  }

  public logout() {
    console.log("[XMPPService][logout]");

    if (this.networkSubscription$) {
      this.networkSubscription$.unsubscribe();
    }
    this.xmppLoggedOut = true;
    this.sentNickname = true;
    if (this.xmpp && this.xmpp.sessionStarted) {
      this.xmpp.disconnect();
    }
  }

  public getRoomConfig(target: string): Observable<ConversationConfig> {
    console.log("[XMPPService][getRoomConfig] target", target);

    const response = new Subject();

    this.xmpp.getRoomConfig(target, (err, res) => {
      console.log("[XMPPService][getRoomConfig] res & err", res, err);

      if (err === null && res) {
        this.zone.run(() => {
          if (res.mucOwner && res.mucOwner.form && res.mucOwner.form.fields) {
            response.next(this.parseXmppRawConfig(res.mucOwner.form.fields));
          } else {
            response.error(err);
          }
        });
      } else {
        response.error(err);
      }
    });

    return response.asObservable().pipe(take(1));
  }

  public configureRoom(target: string, config?: ConversationConfig): Observable<any> {

    const response = new Subject();

    this.xmpp.configureRoom(target, {
      fields: this.getConversationConfig(config)
    }, (err, res) => {
      this.zone.run(() => {
        if (err) {
          response.error(err);
          console.log(err);
        } else {
          response.next(res);
        }
      });
    });

    return response.asObservable().pipe(take(1));
  }

  private getConversationConfig(config?: ConversationConfig) {
    const mergedConfig = { persistent: 1, isPublic: 1, memberOnly: 0, ...config };

    return [
      { name: "FORM_TYPE", value: "http://jabber.org/protocol/muc#roomconfig" },
      { name: "muc#roomconfig_persistentroom", value: mergedConfig.persistent.toString() },
      { name: "muc#roomconfig_changesubject", value: "1" },
      { name: "muc#roomconfig_publicroom", value: mergedConfig.isPublic.toString() },
      { name: "muc#roomconfig_roomname", value: "" },
      { name: "muc#roomconfig_moderatedroom", value: "0" },
      { name: "muc#roomconfig_membersonly", value: mergedConfig.memberOnly.toString() },
      { name: "muc#roomconfig_whois", value: "anyone" },
      { name: "muc#roomconfig_historylength", value: "5" }
    ];
  }

  private parseXmppRawConfig(fields: { name: string, value: any }[]): ConversationConfig {
    const roomName = fields[_.findIndex(fields, f => f.name === "muc#roomconfig_roomname")].value;
    const persistent = fields[_.findIndex(fields, f => f.name === "muc#roomconfig_persistentroom")].value;
    const isPublic = fields[_.findIndex(fields, f => f.name === "muc#roomconfig_publicroom")].value;
    const memberOnly = fields[_.findIndex(fields, f => f.name === "muc#roomconfig_membersonly")].value;

    const response = {};

    if (!!roomName) {
      response["roomName"] = roomName;
    }

    response["persistent"] = persistent ? 1 : 0;
    response["isPublic"] = isPublic ? 1 : 0;
    response["memberOnly"] = memberOnly ? 1 : 0;

    return response;
  }

  public invite(target: string, newMembers: string[], reason = "1") {
    const invites = newMembers.map(m => {
      this.xmpp.invite(target, [{ to: m, reason: reason }]);
    });
  }

  public getRoomMembers(target) {
    return from(this.xmpp.getRoomMembers(target));
  }

  public kick(target: string, nick: string): Observable<any> {
    const response = new Subject<any>();
    this.xmpp.kick(target, nick, "none", (err, res) => {
      this.zone.run(() => {
        if (err) {
          response.error(err);
        }
        if (res) {
          response.next(res);
        } else {
          response.error("invalid response from xmpp");
        }
      });
    });
    return response.asObservable().pipe(take(1));
  }

  public uploadGroupAvatar(target: string, photo: Photo): Observable<any> {
    const response = new Subject<null>();

    this.xmpp.uploadGroupAvatar(target, photo, (err, res) => {
      this.zone.run(() => {
        if (err) {
          response.error(err);
        } else {
          response.next(res);
        }
      });
    });

    return response.asObservable().pipe(take(1));
  }

  public publishVCards(data: ContactInformation): Observable<any> {
    const response = new Subject<any>();
    this.xmpp.publishVCard(data, (err, res) => {
      console.log("[publishVCard]", err, res);
      this.zone.run(() => {
        if (err) {
          response.error(err);
        }

        response.next(res);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  public publishNick(nick: string): Observable<any> {
    const response = new Subject<any>();

    this.xmpp.publishNick(nick, (err, res) => {
      console.log("[publishNick] xmpp", err, res);
      this.zone.run(() => {
        if (err) {
          response.error(err);
        }
        this.sendPresence(nick);
        response.next(res);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  subscribe(bare) {
    this.xmpp.subscribe(bare);
  }

  unsubscribe(bare) {
    this.xmpp.unsubscribe(bare);
  }

  setSubject(target: string, newTitle: string) {
    this.xmpp.setSubject(target, newTitle);
  }

  public getDiscoItems(item: string): Observable<JID[]> {
    const response = new Subject<JID[]>();

    this.xmpp.getDiscoItems(item, "", (err, res) => {
      this.zone.run(() => {
        if (err) {
          console.error("[XmppService][getDiscoItems] err", err);
          response.error(err);
        } else {
          console.log("[XmppService][getDiscoItems]", item, res);

          if (res) {
            if (res.discoItems && res.discoItems.items) {
              response.next(res.discoItems.items.map((m) => m.jid));
            } else {
              response.next([]);
            }
          } else {
            response.error("no response from server");
          }
        }
      });
    });

    return response.asObservable().pipe(take(1));
  }

  public getDiscoInfo(jid: string): Observable<JID[]> {
    const response = new Subject<JID[]>();

    this.xmpp.getDiscoInfo(jid, "", (err, res) => {
      this.zone.run(() => {
        if (err) {
          console.error("[XmppService][getDiscoInfo] err", err);
          response.error(err);
        } else {
          console.log("[XmppService][getDiscoInfo]", jid, res);

          if (res) {
            if (res.discoInfo) {
              response.next(res.discoInfo);
            } else {
              response.next([]);
            }
          } else {
            response.error("no response from server");
          }
        }
      });
    });

    return response.asObservable().pipe(take(1));
  }

  private _setUpMUCRegisterModule(): void {
    this.xmpp.disco.addFeature("jabber:iq:register");

    this.xmpp.unregisterMemberList = (conversationTarget, cb) => {
      console.log("[XmppService][unregister] called for target: ", conversationTarget);
      return this.xmpp.sendIq({
        from: this.xmpp.jid.bare,
        to: conversationTarget,
        type: "set",
        query: { xmlns: "xmpp:vnctalk:unregister" }
      }, cb);
    };
  }

  // we have only 'unregister' flow, since a registration is done automatically
  // at the backend side and send an invite message
  public unregisterMemberList(conversationTarget: string): Observable<any> {
    const response = new Subject<any>();
    this.xmpp.unregisterMemberList(conversationTarget, (err, res) => {
      this.zone.run(() => {
        if (res) {
          response.next(res);
        } else {
          response.error(err);
        }
      });
    });

    return response.asObservable().pipe(take(1));
  }

  public getLastActivity(bare: string): Observable<any> {
    // console.log("[XMPPService][getLastActivity] bare", bare);

    const response = new Subject<any>();
    if (this.xmpp) {
      this.xmpp.getLastActivity(bare, (err, res) => {
        if (res) {
          // console.log("[XMPPService][getLastActivity] res", res.lastActivity, bare);
          response.next(res);
        } else {
          console.error("[XMPPService][getLastActivity] error", bare, err);
          response.error(err);
        }
      });
    } else {
      response.next("0");
    }

    return response.asObservable().pipe(take(1));
  }

  private _setupLastActivityModule(): void {
    this.xmpp.disco.addFeature("jabber:iq:last");
    const NS = "urn:ietf:params:xml:ns:xmpp-stanzas";
    const types = this.xmpp.stanzas.utils;
    const Req = this.xmpp.stanzas.define({
      name: "query",
      element: "query",
      namespace: NS,
      fields: {
        "xmlns": types.attribute("xmlns"),
        "remove": types.textSub("", "remove")
      }
    });

    this.xmpp.stanzas.withIQ((Iq) => {
      this.xmpp.stanzas.extend(Iq, Req);
    });

    this.xmpp.getLastActivity = (bare, cb) => {
      return this.xmpp.sendIq({
        from: this.xmpp.jid.full,
        to: bare,
        type: "get",
        query: { xmlns: "jabber:iq:last" }
      }, cb);
    };
  }

  public getLastActivityBatch(bareJids: string[]): Observable<any> {
    const response = new Subject<any>();
    // console.log("[XMPPService][getLastActivityBatch]1");
    if (this.xmpp) {
      // console.log("[XMPPService][getLastActivityBatch]2", bareJids);
      this.xmpp.getLastActivityBatch(bareJids, (err, res) => {
        // console.log("[XMPPService][getLastActivityBatch] res", res);
        if (res) {
          response.next(res);
        } else {
          console.error("[XMPPService][getLastActivityBatch] error", bareJids, err);
          response.error(err);
        }
      });
    } else {
      response.next({});
    }

    return response.asObservable().pipe(take(1));
  }

  private _setupLastActivityBatchModule(): void {
    this.xmpp.disco.addFeature("jabber:iq:batch");

    const types = this.xmpp.stanzas.utils;

    const Req = this.xmpp.stanzas.define({
      name: "query",
      element: "query",
      namespace: "jabber:iq:batch",
      fields: {
        xmlns: types.attribute("xmlns"),
        jids: types.multiTextSub(null, "jid"),
      }
    });

    this.xmpp.stanzas.withIQ((Iq) => {
      this.xmpp.stanzas.extend(Iq, Req);
    });

    this.xmpp.getLastActivityBatch = (bareJids, cb) => {
      return this.xmpp.sendIq({
        from: this.xmpp.jid.full,
        type: "get",
        query: { xmlns: "jabber:iq:batch", jids: bareJids }
      }, cb);
    };
  }

  private _setUpHTTPUploadModule(): void {
    this.xmpp.disco.addFeature("urn:xmpp:http:upload");
    const NS = "urn:xmpp:http:upload";
    const types = this.xmpp.stanzas.utils;
    const Req = this.xmpp.stanzas.define({
      name: "request",
      element: "request",
      namespace: NS,
      fields: {
        "xmlns": types.attribute("xmlns"),
        "filename": types.textSub(NS, "filename"),
        "size": types.textSub(NS, "size"),
        "content-type": types.textSub(NS, "content-type")
      }
    });

    this.xmpp.stanzas.withIQ((Iq) => {
      this.xmpp.stanzas.extend(Iq, Req);
    });
    this.xmpp.requestSlot = (opts, cb) => {
      return this.xmpp.sendIq({
        from: this.xmpp.jid.bare,
        to: this.xmpp.jid.domain,
        type: "get",
        request: Object.assign({
          xmlns: "urn:xmpp:http:upload"
        }, opts || {})
      }, cb);
    };
  }

  private _setUpCustomStanzaHadler(): void {
    this.xmpp.off("stream:data"); // if we do not off then it duplicates the event
    this.xmpp.on("stream:data", (data) => {
      const json = data.toJSON();

      this.xmpp.emit(data._eventname || data._name, json);

      // IQ
      if (data._name === "iq") {
        // console.log("[XmppService][_setUpCustomStanzaHadler] iq", json);

        json._xmlChildCount = 0;
        _.forEach(data.xml.childNodes, function(child) {
          if (child.nodeType === 1) {
            json._xmlChildCount += 1;
          }
        });

        if (data.xml && data.xml.children && data.xml.children[0]) {
          const firstChildNodeName = data.xml.children[0].nodeName;
          const firstChildXmlns = data.xml.children[0].attrs.xmlns;

          // File upload request slot response
          if (firstChildNodeName === "slot") {
            this.xmpp.emit("vnc:httpupload", data.xml);

            try {
              json.httpupload = {
                get: data.xml.children[0].children[0].children[0],
                put: data.xml.children[0].children[1].children[0]
              };
            } catch (e) {
              console.error("[XMPPService][onStream:data]", e);
            }

            // Last Activity response
          } else if (firstChildNodeName === "query" && data.xml.children[0].attrs.seconds) {
            try {
              json.lastActivity = data.xml.children[0].attrs.seconds;
            } catch (e) {
              console.error("[XMPPService][onStream:data]", e);
            }

            // Last Activity Batch response
          } else if (firstChildNodeName === "query" && firstChildXmlns === "jabber:iq:last"
            && data.xml.children[0].children && data.xml.children[0].children[0].nodeName === "result") {

            try {
              json.lastActivityBatchResults = {};
              for (const resSubelement of data.xml.children[0].children) {
                json.lastActivityBatchResults[resSubelement.attrs.jid] = {
                  seconds: resSubelement.attrs.seconds,
                  photo: resSubelement.attrs.photo
                };
              }
            } catch (e) {
              console.error("[XMPPService][onStream:data]", e);
            }
          }
        }

        // Message
      } else if (data._name === "message" || data._name === "presence" || data._name === "iq") {
        this.xmpp.sm.handle(json);
        this.xmpp.emit("stanza", json);

        // SM ask
      } else if (data._name === "smAck") {
        return this.xmpp.sm.process(json);

        // SM request
      } else if (data._name === "smRequest") {
        return this.xmpp.sm.ack();
      }

      if (json.id) {
        this.xmpp.emit("id:" + json.id, json);
        this.xmpp.emit(data._name + ":id:" + json.id, json);
      }
    });
  }

  markActive(): void {
    if (this.isXmppConnected && this.networkOnline) {
      try {
        this.xmpp.markActive();
      } catch (e) {
        console.log("markActive failed with: ", e);
      }

    }
  }

  markInactive(): void {
    if (this.isXmppConnected && this.networkOnline) {
      console.log("[XmppService][markInactive]");

      this.LastInactiveTime = Date.now() / 1000;
      this.xmpp.LastInactiveTime = Date.now();
      try {
        this.xmpp.markInactive();
      } catch (e) {
        console.error("[XmppService][markInactive]", e);
      }
    }
  }

  enableKeepAlive(): void {
    if (this.isXmppConnected && this.networkOnline) {
      const opts = {
        timeout: environment.xmppTimeout,
        interval: environment.xmppTimeout / 2
      };

      console.log("[XmppService][enableKeepAlive]", opts);

      this.xmpp.enableKeepAlive(opts);
    }
  }

  disableKeepAlive(): void {
    if (this.isXmppConnected && this.networkOnline) {
      console.log("[XmppService][disableKeepAlive]");
      this.xmpp.disableKeepAlive();
    }
  }

  public setRoomAffiliation(room: string, jid: string, affiliation: string): Observable<any> {
    console.log("[setRoomAffiliation]", room, jid, affiliation);
    const response = new Subject<any>();
    let reason = affiliation === "owner" ? "change_owner" : "kick";
    if (affiliation === "member" || affiliation === "admin" || affiliation === "moderator") {
      reason = "set_role";
    }
    this.xmpp.setRoomAffiliation(room, jid, affiliation, reason, (err, data) => {
      console.log("[setRoomAffiliation] xmpp", room, jid, affiliation, err, data);
      if (err === null) {
        response.next(data);
      } else {
        response.error(err);
      }
    });
    return response.asObservable().pipe(take(1));
  }

}

enum XmppConnectionStatus { Disconnected, Connecting, Connected, SessionStarted }
