import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, NgZone } from "@angular/core";
import {
  getUserProfile
} from "../reducers";
import { Subject } from "rxjs";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { ConfigService } from "../config.service";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers/app";
import { takeUntil, debounceTime, filter, take } from "rxjs/operators";
import { isArray } from "util";
import { environment } from "environments/environment";
import { AppsDialogComponent } from "app/shared/components/apps-dialog/apps-dialog.component";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatMenuTrigger } from "@angular/material/menu";
import { CommonUtil } from "app/utils/common.util";
import { AuthService } from "app/shared/services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { Broadcaster } from "app/shared/services/broadcaster.service";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { VersionDialogComponent } from "app/shared/components/version-dialog/version-dialog.component";
import { HelpDialogComponent } from "app/shared/components/help-dialog/help-dialog.component";
import { ServiceDeskComponent } from "app/shared/components/service-desk-dialog/service-desk-dialog.component";

@Component({
  selector: "vp-header",
  templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentUser: any;
  searchKeyword: string;
  searchMode = false;
  showUserMenu = false;
  profileBackground = "url(" + CommonUtil.getFullUrl(environment.profileBackgroundImage) + ")";
  isSearchQueryExist = false;
  searchText = "";
  switchApp = false;
  currentUrl = "";
  isCordovaOrElectron: boolean;
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  penIcon = CommonUtil.getFullUrl("/assets/img/pen.svg");
  filterIcon = CommonUtil.getFullUrl("/assets/img/filter.svg");
  isMobileScreen = false;
  appLogo = "";
  appLogoDesktop = CommonUtil.getFullUrl(environment.appLogo);
  textLogo = environment.textLogo;
  brandName = environment.mobileTextLogo;
  avatarURL: string;
  searchFor = localStorage.getItem("getSearchFor") || "mail";
  includeSharedItems = (localStorage.getItem("includeSharedItems") || "true") === "true";
  isHandset: boolean;
  searchControl: FormControl = new FormControl("");
  private searchKeyDown = new Subject();
  private isAlive$ = new Subject<boolean>();
  termsOfUse: any;
  faqURL: any;
  serviceDeskURL: any;
  dataPrivacy: any;
  userManual: any;
  constructor(
    private auth: AuthService,
    private router: Router,
    private config: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private titleService: Title,
    private broadCaster: Broadcaster
  ) {
    console.log("[HeaderComponent] constructor", new Date());
    this.isCordovaOrElectron = environment.isCordova || environment.isElectron;
    this.currentUrl = this.router.routerState.snapshot.url;
    this.changeDetectorRef.markForCheck();
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.isHandset = this.breakpointObserver.isMatched([Breakpoints.Handset]);
    this.broadCaster.on<any>("keyword").pipe(takeUntil(this.isAlive$)).subscribe(data => {
      console.log("[HeaderComponent]", data);
      this.searchControl.patchValue(data);
    });
    this.config.currentLanguage.asObservable().pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.translate.get("MULTI_CONFERENCE_BOARD").pipe(take(1)).subscribe(text => {
        this.titleService.setTitle(text);
      });
    });
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isHandset = true;
        } else {
          this.isHandset = false;
        }
        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnInit() {
    console.log("[HeaderComponent] ngOnInit", new Date());
    this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(profile => {
      this.currentUser = profile.user;
      this.changeDetectorRef.markForCheck();
    });

    this.router.events.pipe(takeUntil(this.isAlive$)).pipe(filter(e => e instanceof NavigationEnd)).subscribe(data => {
      this.currentUrl = this.router.routerState.snapshot.url;
      this.changeDetectorRef.markForCheck();
    });

    this.broadCaster.on<any>("closeSearch").pipe(takeUntil(this.isAlive$)).subscribe(apps => {
      this.clearSearchText();
    });

    this.setUrls(CommonUtil.getDefaultLang());
  }

  getSingleEmail(emails) {
    if (isArray(emails)) {
      return emails[0];
    } else if (emails) {
      return emails;
    }
    return null;
  }

  openMenu(): void {
    this.trigger.openMenu();
  }

  toggleMobileSearch(): void {
    this.searchMode = !this.searchMode;
    this.clearSearchText();
  }

  searchOnKeyUp() {
    this.searchKeyDown.next(true);
  }

  clearSearchText(): void {
    this.searchKeyword = "";
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  switchAppMenuOption() {
    if (this.isMobileScreen) {
      this.showUserMenu = true;
    } else {
      this.switchApp = this.switchApp ? false : true;
    }
    this.changeDetectorRef.markForCheck();
  }

  closeAppSwitcher() {
    this.switchApp = false;
    this.changeDetectorRef.markForCheck();
  }

  selectedOption(event: MatAutocompleteSelectedEvent): void {
    this.searchKeyword = event.option.value as string;
  }

  logout(): void {
    this.showUserMenu = false;
    this.auth.logoutWebAndApp();
    this.changeDetectorRef.markForCheck();
  }

  navigatorTo(url: string): void {
    this.currentUrl = this.router.routerState.snapshot.url;
    this.router.navigate([url]);
    this.searchControl.patchValue("");
  }

  openAppSwitcherDialog(): void {
    this.matDialog.open(AppsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }

  search() {
    this.router.navigateByUrl("/search/" + this.searchControl.value);
  }

  versionDialog(): void {
    this.matDialog.open(VersionDialogComponent, {
      maxWidth: "100%",
      width: "400px",
      height: "536px",
      autoFocus: false,
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel"
    });
  }

  manualDialog(): void {
    this.matDialog.open(HelpDialogComponent, {
      maxWidth: "100%",
      width: "520px",
      height: "360px",
      autoFocus: false,
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel"
    });
  }

  deskDialog(): void {
    this.matDialog.open(ServiceDeskComponent, {
      maxWidth: "100%",
      width: "520px",
      height: "340px",
      autoFocus: false,
      backdropClass: "mcb-form-backdrop",
      panelClass: "mcb-form-panel"
    });
  }

  setUrls(browserLang) {
    if (this.config.URLS.serviceDesk) {
      this.serviceDeskURL = this.config.URLS.serviceDesk;
    } else {
      this.serviceDeskURL = "https://redmine.vnc.biz/helpdesk/incidents/new";
    }
    if (browserLang === "en") {
      if (this.config.URLS.faq) {
        this.faqURL = this.config.URLS.faq;
      } else {
        this.faqURL = "https://portal.vnc.biz/product-area/faq/vnccommander-faq";
      }

      if (this.config.URLS.LegalNoticeTermOfUse) {
        this.termsOfUse = this.config.URLS.LegalNoticeTermOfUse;
      } else {
        this.termsOfUse = "https://vnclagoon.com/terms";
      }

      if (this.config.URLS.LegalNoticeDataPrivacy) {
        this.dataPrivacy = this.config.URLS.LegalNoticeDataPrivacy;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/data-privacy-policy/";
      }
      if (this.config.URLS.userManual) {
        this.userManual = this.config.URLS.userManual;
      } else {
        this.userManual = "https://en.docs.vnc.biz/vnccommander/usermanual/";
      }

    } else {
      if (this.config.URLS.faq_de) {
        this.faqURL = this.config.URLS.faq_de;
      } else {
        this.faqURL = "https://portal.vnc.biz/product-area/faq/vnccommander-faq-de";
      }
      if (this.config.URLS.LegalNoticeTermOfUse_de) {
        this.termsOfUse = this.config.URLS.LegalNoticeTermOfUse_de;
      } else {
        this.termsOfUse = "https://vnclagoon.com/de/Nutzungsbedingungen/";
      }

      if (this.config.URLS.LegalNoticeDataPrivacy_de) {
        this.dataPrivacy = this.config.URLS.LegalNoticeDataPrivacy_de;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/de/datenschutzerklaerung/";
      }

      if (this.config.URLS.userManual_de) {
        this.userManual = this.config.URLS.userManual;
      } else {
        this.userManual = "https://de.docs.vnc.biz/vnccommander/usermanual/";
      }
    }
    this.changeDetectorRef.markForCheck();
  }
}
