import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

setupTheme();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
function loadExternalStyle(styleUrl: string) {
  return new Promise((resolve, reject) => {
    let styleElement = document.createElement("link");
    styleElement.rel = "stylesheet";
    styleElement.type = "text/css";
    styleElement.href = styleUrl;
    styleElement.onload = () => {
      showApp();
      resolve();
    };
    document.head.appendChild(styleElement);
    styleElement = null;
  });
}

function loadExternalScript(scriptUrl: string) {
  return new Promise(resolve => {
    let scriptElement = document.createElement("script");
    scriptElement.src = scriptUrl;
    scriptElement.onload = resolve;
    document.body.appendChild(scriptElement);
    scriptElement = null;
  });
}

function showApp() {
  document.querySelector("vp-root").removeAttribute("style");
}

function setupTheme() {
  const HASH_VALUE = "HASH_VALUE";
  console.log("[setupTheme]", new Date(), environment);
  if (environment.isCordova) {
    loadExternalScript("cordova.js");
    loadExternalStyle("assets/css/smooth-scroll.css");
  }
  const currentName = localStorage.getItem("theme") || environment.theme;
  const themePath = `assets/theme/`;
  document.querySelector("vp-root").setAttribute("style", "display: none");
  if (environment.production || environment.isCordova || environment.isElectron) {
    loadExternalStyle(themePath + "mcb.css").then(() => { showApp(); }).catch(() => { showApp(); });
  } else {
    loadExternalScript(themePath + "mcb.js").then(() => { showApp(); }).catch(() => { showApp(); });
  }
  document.title = environment.title;
}
